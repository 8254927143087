import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import supabase from './Supabase';
import posthog from 'posthog-js';
import { get } from 'lodash';

interface AuthContextType {
  user: any;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  firstName: string | null;
}

const defaultAuthContext: AuthContextType = {
  user: null,
  signIn: async () => { },
  signOut: async () => { },
  firstName: null,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const fetchSession = async () => {
      const result = await supabase.auth.getSession();
      setUser(result?.data?.session?.user ?? null);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);

      if (session?.user) {
        const userId = session.user.id;
        const userEmail = session.user.email;

        posthog.identify(userId, {
          email: userEmail,
        });

        posthog.people.set({
          distinct_id: userId,
          email: userEmail,
          name: getFirstName(),
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  const getFirstName = () => {
    const supabaseFirstName = user?.user_metadata?.first_name;
    const googleFirstName = user?.user_metadata?.full_name?.split(' ')[0];
    return supabaseFirstName || googleFirstName || null;
  };

  return (
    <AuthContext.Provider value={{
      user,
      signIn,
      signOut,
      firstName: getFirstName(),
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import './SplashScreen.css';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img className="splash-screen-logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
      <div className="splash-screen-text">
        <img className="splash-screen-text-image" src={`${process.env.PUBLIC_URL}/splash.png`} alt="Logo" />
      </div>
    </div>
  );
};

export default SplashScreen;
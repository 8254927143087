import { useAuth } from './AuthContext';
import './LetsGo.css';

import React from 'react';

interface LetsGoProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onSignupClick: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

const LetsGo = ({ onClick, onSignupClick, children }: LetsGoProps) => {
  const { user } = useAuth();

  return (
    <div className="lets-go">
      <div className="headerContainer">
        <div className="header">
          <div>Chef Yori</div>
          {!user && <div className='lets-go-signup' onClick={onSignupClick}>Sign up</div>}
        </div>
      </div>
      <div className="contentContainer">
        <img className="lets-go-logo" src={`${process.env.PUBLIC_URL}/welcome.png`} alt="Logo" />
        <button className="lets-go-button" onClick={onClick}>Get started</button>
      </div>
      {children}
    </div>
  );
};

export default LetsGo;